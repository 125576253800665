<template>
  <div class="news-page">
    <!-- 会员单位介绍 -->
    <main-nav />
    <div class="news-bar">
      <div class="bannerWrapper">
        <img src="../../assets/serviceBanner.png" alt="" />
      </div>
    </div>
    <transition name="fade" v-if="typeMark == 0 && currentPage == 1">
      <div class="newsWrapper">
        <div class="newsItem">
          <div class="newsLeftItem">
            <img src="../../assets/unit/xiang02.png" alt="" />
          </div>
          <div class="newsRightItem" @click="toDetail(1)">
            <p>副会长-向振鹏</p>
            <div class="ItemContent newsOmit">
              北京德和衡律师事务所是1993年创立的中国早期合伙制律所德衡律师集团在北京创建德总部机构。
            </div>
          </div>
        </div>
        <div class="newsItem">
          <div class="newsLeftItem">
            <img src="../../assets/unit/long02.png" alt="" />
          </div>
          <div class="newsRightItem" @click="toDetail(2)">
            <p>理事-龙安平</p>
            <div class="ItemContent newsOmit">
              国洋商业连锁管理有限公司成立于2005年，是国洋投资控股公司旗下的全资子公司，其经营宗旨是：打造烟酒连锁第一品牌。公司秉承“成为顾客满意、同行尊重、员工信赖的烟酒连锁第一品牌”的企业愿景，致力于为消费者呈选臻品，专注烟酒产品品质以及服务品质，深得客户的信赖。
            </div>
          </div>
        </div>
        <div class="newsItem">
          <div class="newsLeftItem">
            <img src="../../assets/unit/tan02.png" alt="" />
          </div>
          <div class="newsRightItem" @click="toDetail(3)">
            <p>理事-谭灵芝</p>
            <div class="ItemContent newsOmit">
              筑壳(深圳)科技有限公司是以场景化建筑设计装饰平台，致力于以真实案例作场景化展示，旨在重塑整个行业，让空间更美好。
            </div>
          </div>
        </div>
        <div class="newsItem">
          <div class="newsLeftItem">
            <img src="../../assets/unit/xiao02.png" alt="" />
          </div>
          <div class="newsRightItem" @click="toDetail(4)">
            <p>副会长-肖晓红</p>
            <div class="ItemContent newsOmit">
              福建麦瑞森医疗器械有限公司，2011年07月13日成立，是一家具有二、三类医疗器械经营资质的专业器械公司，公司秉承“争创一流企业服务人民健康”的经营理念，长期服务于福建省内各大医疗机构。
            </div>
          </div>
        </div>
        <div class="newsItem">
          <div class="newsLeftItem">
            <img src="../../assets/unit/user.png" alt="" />
          </div>
          <div class="newsRightItem" @click="toDetail(5)">
            <p>副会长-王珊</p>
            <div class="ItemContent newsOmit">
              鑫梓润智慧城市管家成立于2006年，在全国率先探索通过购买服务，建立政企协同合作关系，加快加强构建发展新格局，首创 “ 花园街区 ” 模式，创立“ 1+N 智慧城市管家 ” 品牌，是全国首家智慧城市管理+城市公共服务一体化的上市企业。
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Footer />
  </div>
</template>

<script>
import MainNav from "../../components/MainNav/index";
import Footer from "../../components/Footer.vue";
export default {
  name: "eleDem",
  data() {
    return {
      typeMark: 0, // 默认为 供应
      pageNum: 2, // 总页
      currentPage: 1, // 当前页
      stripNum: 6, // 页面条数
    };
  },
  components: {
    MainNav,
    Footer
  },
  mounted() {
    this.typeMark = this.$route.params.newType ? this.$route.params.newType : 0;
    console.log(this.typeMark, '000000000000000000---');
  },
  methods: {
    changeOptions(bool) {
      this.typeMark = bool;
      this.currentPage = 1;
      if (bool == 0) {
        this.pageNum = 2;
      } else {
        this.pageNum = 1;
      }
    },
    // 点击跳转详情页
    toDetail(index) {
        console.log('UnitIntroduce'+index);
      this.$router.push({
        name: 'UnitIntroduce'+index,
      });
    }
  },
};
</script>

<style scoped lang="less">
@import "../../styles/news";
.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}


@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/.newsListNav {
    height: 6rem;
    .ListNav/deep/ {
      margin-right: 15rem;
    }
  }
}
</style>